import { ModelBase } from './ModelBase'

export class ExtensionVehicle extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.damageType = this._str(raw.damageType)
    this.docType = this._str(raw.docType).trim()

    this.estimatedRetailValue = this._strFiat(raw.estimatedRetailValue)
    this.imageUrl = this._str(raw.imageUrl)
    this.itemNumber = this._num(raw.itemNumber)

    this.maker = this._str(raw.maker)
    this.model = this._str(raw.model)
    this.year = this._str(raw.year)

    this.odometer = this._num(raw.odometer) || 0
    this.odometerStatus = this._str(raw.odometerStatus)
    this.vin = this._str(raw.vin).trim()

    this.location = this._str(raw.yardName)
    this.yardNumber = this._num(raw.yardNumber)
  }

  get name () {
    return [this.year, this.maker, this.model].join(' ')
  }
}
