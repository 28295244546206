<template>
  <div class="cells-cmn__cell won-status-cell">
    <span
      class="won-status-cell__status cells-cmn__str"
      :class="{ 'won-status-cell__status_on-approval': isOnApproval }"
    >
      {{ translatedStatus }}
    </span>
  </div>
</template>

<script>
import { CopartWinner } from 'Models/CopartWinner'
import { COUNTER_BID_STATUSES } from '../constants'

export default {
  name: 'won-invoice-cell',

  props: {
    item: { type: CopartWinner, required: true },
  },

  computed: {
    translatedStatus () {
      const translationsMap = {
        [COUNTER_BID_STATUSES.AWAITING_SELLER_RESPONSE]:
          this.$t('STATUS_AWAITING_SELLER_RESPONSE'),
        [COUNTER_BID_STATUSES.SELLER_COUNTERED]:
          this.$t('STATUS_SELLER_COUNTERED'),
        [COUNTER_BID_STATUSES.AWAITING_APPROVAL]:
          this.$t('STATUS_AWAITING_APPROVAL'),
      }

      const status = this.item.counterBidStatus
      return translationsMap[status] || status.replace(/_/g, ' ')
    },

    isOnApproval () {
      return this.item.counterBidStatus ===
        COUNTER_BID_STATUSES.AWAITING_APPROVAL
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.won-status-cell {
  &__status {
    color: $color-status-waiting;

    &_on-approval {
      color: $color-flag-is-error;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "STATUS_AWAITING_SELLER_RESPONSE": "AWAITING SELLER RESPONSE",
    "STATUS_SELLER_COUNTERED": "SELLER COUNTERED",
    "STATUS_AWAITING_APPROVAL": "AWAITING APPROVAL"
  },
  "ka": {
    "STATUS_AWAITING_SELLER_RESPONSE": "ᲒᲐᲛᲧᲘᲓᲕᲔᲚᲘᲡ ᲞᲐᲡᲣᲮᲘᲡ ᲛᲝᲚᲝᲓᲘᲜᲨᲘ",
    "STATUS_SELLER_COUNTERED": "ᲙᲝᲜᲢᲠᲑᲘᲓᲘ ᲒᲐᲒᲖᲐᲕᲜᲘᲚᲘᲐ",
    "STATUS_AWAITING_APPROVAL": "ᲓᲐᲓᲐᲡᲢᲣᲠᲔᲑᲘᲡ ᲛᲝᲚᲝᲓᲘᲜᲨᲘ"
  },
  "ru": {
    "STATUS_AWAITING_SELLER_RESPONSE": "ОЖИДАНИЕ ОТВЕТА ПРОДАВЦА",
    "STATUS_SELLER_COUNTERED": "ПРОДАВЕЦ ОТВЕТИЛ",
    "STATUS_AWAITING_APPROVAL": "ОЖИДАНИЕ ПОДТВЕРЖДЕНИЯ"
  },
  "uk": {
    "STATUS_AWAITING_SELLER_RESPONSE": "ОЧІКУВАННЯ ВІДПОВІДІ ПРОДАВЦЯ",
    "STATUS_SELLER_COUNTERED": "ПРОДАВЕЦЬ ВІДПОВІВ",
    "STATUS_AWAITING_APPROVAL": "ОЧІКУВАННЯ ПІДТВЕРДЖЕННЯ"
  }
}
</i18n>
