import { ModelBase } from './ModelBase'
import { Identity } from './Identity'

export class ExtensionParticipation extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)
    const r11s = raw.relationships || {}

    const identity = ModelBase.modelFromCache(
      Identity,
      r11s.identity,
      cache
    )

    this.fullName = identity.fullName
    this.email = identity.email
    this.avatarLink = identity.avatarLink
    this.accountId = identity.id
  }
}
