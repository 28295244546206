<template>
  <button
    class="won-export"
    :disabled="isLoading"
    @click="exportWon"
  >
    <template v-if="isLoading">
      {{ $t('LOADING_MSG') }}
    </template>

    <template v-else>
      {{ $t('EXPORT_BTN') }}
    </template>
  </button>
</template>

<script>
import { downloadCsvFile } from 'Utils/downloadCsvFile'
import { showError } from 'Utils/notifications'

import { mapActions, mapGetters } from 'vuex'
import { extensionWonActions, extensionWonGetters } from '../store/types'
import { userGetters } from 'Store/entities/User/types'

import {
  EXTENSION_WON_LIST_TEMPLATES,
  COUNTER_BID_STATUSES
} from '../constants'

import { formatDate, isNullDate } from 'Utils/dateHelpers'

export default {
  name: 'won-export',
  props: {
    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator (value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      exportList: `ui/extension-won/${extensionWonGetters.EXPORT_LIST}`,
      platformId: `entities/user/${userGetters.PLATFORM_ID}`
    }),

    exportTableHead () {
      const headParts = [this.$t('EXPORT_TABLE_HEAD_BASE')]

      switch (this.template) {
        case EXTENSION_WON_LIST_TEMPLATES.won:
          headParts.push(this.$t('EXPORT_TABLE_HEAD_WON'))
          break

        case EXTENSION_WON_LIST_TEMPLATES.openItems:
          headParts.push(this.$t('EXPORT_TABLE_HEAD_OPEN_ITEMS'))
          break

        case EXTENSION_WON_LIST_TEMPLATES.history:
          headParts.push(this.$t('EXPORT_TABLE_HEAD_HISTORY'))
          break
      }

      return headParts.join(',')
    },

    wonBlob () {
      const wonRows = this.exportList.map(item => {
        return [
          item.vehicle.id,
          this.getLotName(item),
          item.vehicle.vin,
          this.getWinnerName(item),
          item.buyerNumber || this.$t('COMMON.SYMBOLS.MDASH'),
          item.vehicle.docType || this.$t('COMMON.SYMBOLS.MDASH'),
          item.vehicle.location,
          ...this.getTemplateCells(item)
        ].join(',')
      })

      return [this.exportTableHead, ...wonRows].join('\n')
    }
  },

  methods: {
    ...mapActions('ui/extension-won', {
      loadExportList: extensionWonActions.LOAD_EXPORT_LIST,
    }),

    async exportWon () {
      try {
        this.isLoading = true
        await this.loadExportList()
        this.isLoading = false

        const filename = [
          formatDate(new Date(), 'yyyy-MM-dd'),
          'won'
        ].join('-')

        downloadCsvFile(this.wonBlob, filename)
      } catch (e) {
        showError(this.$t('TOASTS.UNEXPECTED_ERROR'))
        console.error(e)
      }
    },

    getTemplateCells (item) {
      const formatCurrencyCsv = (amount, currency) => {
        return this.$fc(amount, currency).replace(/,/g, '.')
      }

      switch (this.template) {
        case EXTENSION_WON_LIST_TEMPLATES.won:
          return [
            formatDate(item.saleDate, this.$t('DATE_FORMATS.MONTH_DAY_YEAR_NUMERAL')),
            formatCurrencyCsv(item.bidAmount, item.currency),
            formatCurrencyCsv(item.feeAmount, item.currency),
            formatCurrencyCsv(item.invoiceAmount, item.currency),
            formatCurrencyCsv(item.amountDue, item.currency),
            item.gatePassCode || this.$t('COMMON.SYMBOLS.MDASH'),
          ]

        case EXTENSION_WON_LIST_TEMPLATES.openItems:
          return [
            this.getTranslatedStatus(item),
            formatCurrencyCsv(item.currentBid, item.currency),
            formatCurrencyCsv(item.myBid, item.currency)
          ]

        case EXTENSION_WON_LIST_TEMPLATES.history:
          return [
            isNullDate(item.leftLocationDate)
              ? this.$t('COMMON.SYMBOLS.MDASH')
              : formatDate(
                item.leftLocationDate,
                this.$t('DATE_FORMATS.MONTH_DAY_YEAR_NUMERAL')
              ),
            this.$fc(item.invoiceAmount, item.currency)
          ]

        default:
          return []
      }
    },

    getLotName (item) {
      if (this.template === EXTENSION_WON_LIST_TEMPLATES.history) {
        return item.id
      }

      return item.description || item.vehicle.name
    },

    getWinnerName (item) {
      if (item.lookingForWinner) {
        return this.$t('LOOKING_FOR_WINNER_MSG')
      }

      if (item.participation.accountId === this.platformId) {
        return this.$t('DIRECT_PURCHASE_MSG')
      }

      if (item.participation.id) {
        return item.participation.fullName
      }

      return this.$t('COMMON.SYMBOLS.MDASH')
    },

    getTranslatedStatus (item) {
      const translationsMap = {
        [COUNTER_BID_STATUSES.AWAITING_SELLER_RESPONSE]:
          this.$t('STATUS_AWAITING_SELLER_RESPONSE'),
        [COUNTER_BID_STATUSES.SELLER_COUNTERED]:
          this.$t('STATUS_SELLER_COUNTERED'),
        [COUNTER_BID_STATUSES.AWAITING_APPROVAL]:
          this.$t('STATUS_AWAITING_APPROVAL'),
      }

      const status = item.counterBidStatus
      return translationsMap[status] || status.replace(/_/g, ' ')
    }
  }
}
</script>

<style lang="scss" scoped>
.won-export {
  background: none;
  border: none;
  font-size: 1.2em;
  font-weight: 500;
  line-height: 1.5;
  color: $color-sys-info;
  transition: color 150ms;

  &:hover,
  &:disabled {
    color: rgba($color-sys-info, 0.8);
  }
}
</style>

<i18n>
{
  "en": {
    "LOADING_MSG": "Loading…",
    "EXPORT_BTN": "Export",
    "EXPORT_TABLE_HEAD_BASE": "Lot ID,Car,VIN,Winner,Buyer Number,Doc Type,Location",
    "EXPORT_TABLE_HEAD_WON": "Sale Date,Vehicle,Fee A,Invoice,Due,Gate Pass PIN",
    "EXPORT_TABLE_HEAD_OPEN_ITEMS": "Status,Current Bid,Max Bid",
    "EXPORT_TABLE_HEAD_HISTORY": "Left,Invoice",
    "LOOKING_FOR_WINNER_MSG": "Looking For Winner",
    "DIRECT_PURCHASE_MSG": "Direct Purchase",
    "STATUS_AWAITING_SELLER_RESPONSE": "AWAITING SELLER RESPONSE",
    "STATUS_SELLER_COUNTERED": "SELLER COUNTERED",
    "STATUS_AWAITING_APPROVAL": "AWAITING APPROVAL"
  },
  "ka": {
    "LOADING_MSG": "იტვირთება…",
    "EXPORT_BTN": "გადმოწერა",
    "EXPORT_TABLE_HEAD_BASE": "ლოტის ID,ავტომობილი,VIN,მოგებული,მყიდველის კოდი,დოკ. ტიპი,ლოკაცია",
    "EXPORT_TABLE_HEAD_WON": "გაყიდვის თარიღი,ავტომობილი,საკომისიო,ინვოისი,დასაფარი,კარიბჭის საშვის PIN",
    "EXPORT_TABLE_HEAD_OPEN_ITEMS": "სტატუსი,მიმდინარე ბიჯი,მაქს. ბიჯი",
    "EXPORT_TABLE_HEAD_HISTORY": "გამოვიდა,ინვოისი",
    "LOOKING_FOR_WINNER_MSG": "დაკარგული",
    "DIRECT_PURCHASE_MSG": "პირდაპირ ნაყიდი",
    "STATUS_AWAITING_SELLER_RESPONSE": "ᲒᲐᲛᲧᲘᲓᲕᲔᲚᲘᲡ ᲞᲐᲡᲣᲮᲘᲡ ᲛᲝᲚᲝᲓᲘᲜᲨᲘ",
    "STATUS_SELLER_COUNTERED": "ᲙᲝᲜᲢᲠᲑᲘᲓᲘ ᲒᲐᲒᲖᲐᲕᲜᲘᲚᲘᲐ",
    "STATUS_AWAITING_APPROVAL": "ᲓᲐᲓᲐᲡᲢᲣᲠᲔᲑᲘᲡ ᲛᲝᲚᲝᲓᲘᲜᲨᲘ"
  },
  "ru": {
    "LOADING_MSG": "Загрузка…",
    "EXPORT_BTN": "Экспорт",
    "EXPORT_TABLE_HEAD_BASE": "Лот ID,Авто,VIN,Победитель,Номер покупателя,Тип документа,Локация",
    "EXPORT_TABLE_HEAD_WON": "Дата продажи,Цена,Комиссия,Счёт,Долг,ПИН-код от ворот",
    "EXPORT_TABLE_HEAD_OPEN_ITEMS": "Статус,Текущая ставка,Максимальная ставка",
    "EXPORT_TABLE_HEAD_HISTORY": "Отбыл,Счёт",
    "LOOKING_FOR_WINNER_MSG": "Поиск победителя",
    "DIRECT_PURCHASE_MSG": "Прямая покупка",
    "STATUS_AWAITING_SELLER_RESPONSE": "ОЖИДАНИЕ ОТВЕТА ПРОДАВЦА",
    "STATUS_SELLER_COUNTERED": "ПРОДАВЕЦ ОТВЕТИЛ",
    "STATUS_AWAITING_APPROVAL": "ОЖИДАНИЕ ПОДТВЕРЖДЕНИЯ"
  },
  "uk": {
    "LOADING_MSG": "Завантаження…",
    "EXPORT_BTN": "Експорт",
    "EXPORT_TABLE_HEAD_BASE": "Лот ID,Авто,VIN,Переможець,Номер покупця,Тип документа,Локація",
    "EXPORT_TABLE_HEAD_WON": "Дата продажу,Ціна,Комісія,Рахунок,Борг,ПІН-код від воріт",
    "EXPORT_TABLE_HEAD_OPEN_ITEMS": "Статус,Поточна ставка,Максимальна ставка",
    "EXPORT_TABLE_HEAD_HISTORY": "Відбув,Рахунок",
    "LOOKING_FOR_WINNER_MSG": "Пошук переможця",
    "DIRECT_PURCHASE_MSG": "Пряма покупка",
    "STATUS_AWAITING_SELLER_RESPONSE": "ОЧІКУВАННЯ ВІДПОВІДІ ПРОДАВЦЯ",
    "STATUS_SELLER_COUNTERED": "ПРОДАВЕЦЬ ВІДПОВІВ",
    "STATUS_AWAITING_APPROVAL": "ОЧІКУВАННЯ ПІДТВЕРДЖЕННЯ"
  }
}
</i18n>
