export const extensionWonActions = {
  LOAD_LIST: 'LOAD_LIST',
  LOAD_EXPORT_LIST: 'LOAD_EXPORT_LIST',
  LOAD_NOT_PAID_LIST: 'LOAD_NOT_PAID_LIST',
  LOAD_WON_LIST: 'LOAD_WON_LIST',
  LOAD_OPEN_ITEMS_LIST: 'LOAD_OPEN_ITEMS_LIST',
  LOAD_HISTORY_LIST: 'LOAD_HISTORY_LIST',
  LOAD_MORE: 'LOAD_MORE',
  LOAD_MANAGED_LOGINS: 'LOAD_MANAGED_LOGINS',
  UPDATE_LOT_SALE: 'UPDATE_LOT_SALE',
  SET_IS_LOADING: 'SET_IS_LOADING'
}

export const extensionWonMutations = {
  SET_IS_LOADING: 'SET_IS_LOADING',
  SET_IS_NEXT_LOADING: 'SET_IS_NEXT_LOADING',
  SET_LIST_OPTS: 'SET_LIST_OPTS',
  SET_LIST: 'SET_LIST',
  SET_EXPORT_LIST: 'SET_EXPORT_LIST',
  SET_TOTAL_COUNT: 'SET_TOTAL_COUNT',
  PUSH_LIST: 'PUSH_LIST',
  SET_PAGE_NUMBER: 'SET_PAGE_NUMBER',
  SET_FETCH_FN: 'SET_FETCH_FN',
  SET_MANAGED_LOGINS: 'SET_MANAGED_LOGINS',
}

export const extensionWonGetters = {
  IS_LOADING: 'IS_LOADING',
  IS_NEXT_LOADING: 'IS_NEXT_LOADING',
  LIST: 'LIST',
  EXPORT_LIST: 'EXPORT_LIST',
  LIST_OPTS: 'LIST_OPTS',
  TOTAL_COUNT: 'TOTAL_COUNT',
  PAGE_NUMBER: 'PAGE_NUMBER',
  FETCH_FN: 'FETCH_FN',
  MANAGED_LOGINS: 'MANAGED_LOGINS',
}
