<template>
  <div class="cells-cmn__cell won-invoice-cell">
    <div class="cells-cmn__str">
      <a
        class="won-invoice-cell__link"
        :class="{
          'won-invoice-cell__link_paid':
            item.isPaid || template === EXTENSION_WON_LIST_TEMPLATES.history
        }"
        :href="item.invoiceLink"
        target="_blank"
      >
        {{ $fc(item.invoiceAmount, item.currency) }}
      </a>

      <p
        v-if="item.isPaid && template === EXTENSION_WON_LIST_TEMPLATES.won"
        class="cells-cmn__str cells-cmn__str_sec"
      >
        {{ $t('PAID_MSG') }}
      </p>
    </div>
  </div>
</template>

<script>
import { EXTENSION_WON_LIST_TEMPLATES } from '../constants'
import { CopartWinner } from 'Models/CopartWinner'

export default {
  name: 'won-invoice-cell',

  props: {
    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator (value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      },
    },

    item: {
      type: CopartWinner,
      required: true,
    },
  },

  data () {
    return {
      EXTENSION_WON_LIST_TEMPLATES
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";

.won-invoice-cell {
  &__link {
    color: $color-sys-info;

    &_paid {
      color: $color-dark-grey;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "PAID_MSG": "Paid"
  },
  "ka": {
    "PAID_MSG": "გადახდილი"
  },
  "ru": {
    "PAID_MSG": "Оплачен"
  },
  "uk": {
    "PAID_MSG": "Сплачений"
  }
}
</i18n>
