export const EXTENSION_WON_LIST_TEMPLATES = Object.freeze({
  notPaid: 'notPaid',
  won: 'won',
  openItems: 'openItems',
  history: 'history',
})

export const COUNTER_BID_STATUSES = Object.freeze({
  AWAITING_SELLER_RESPONSE: 'AWAITING_SELLER_RESPONSE',
  SELLER_COUNTERED: 'SELLER_COUNTERED',
  AWAITING_APPROVAL: 'AWAITING_APPROVAL'
})

export const EXTENSION_WON_PAGE_LIMIT = 20
