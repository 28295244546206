<template>
  <div
    class="won-list-head extension-won-cmn__row"
    :class="{
      'extension-won-cmn__row_won': isWonTemplate,
      'extension-won-cmn__row_history': isHistoryTemplate
    }"
  >
    <span class="won-list-head__cell">
      {{ $t('CAR_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('WINNER_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('DOC_TYPE_HEAD_CELL') }}
    </span>

    <span class="won-list-head__cell">
      {{ $t('LOCATION_HEAD_CELL') }}
    </span>

    <template v-if="isWonTemplate">
      <span class="won-list-head__cell">
        {{ $t('VEHICLE_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('FEE_A_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('INVOICE_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('DUE_HEAD_CELL') }}
      </span>
    </template>

    <template v-else-if="template === EXTENSION_WON_LIST_TEMPLATES.openItems">
      <span class="won-list-head__cell">
        {{ $t('STATUS_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('CURRENT_BID_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('MAX_BID_HEAD_CELL') }}
      </span>
    </template>

    <template v-else-if="template === EXTENSION_WON_LIST_TEMPLATES.history">
      <span class="won-list-head__cell">
        {{ $t('LEFT_HEAD_CELL') }}
      </span>

      <span class="won-list-head__cell">
        {{ $t('INVOICE_HEAD_CELL') }}
      </span>
    </template>
  </div>
</template>

<script>
import { EXTENSION_WON_LIST_TEMPLATES } from '../constants'

export default {
  name: 'won-list-head',

  props: {
    template: {
      type: String,
      default: EXTENSION_WON_LIST_TEMPLATES.won,
      validator (value) {
        return Object.values(EXTENSION_WON_LIST_TEMPLATES).includes(value)
      },
    },
  },

  data () {
    return {
      EXTENSION_WON_LIST_TEMPLATES,
    }
  },

  computed: {
    isWonTemplate () {
      return this.template === EXTENSION_WON_LIST_TEMPLATES.won ||
        this.template === EXTENSION_WON_LIST_TEMPLATES.notPaid
    },

    isHistoryTemplate () {
      return this.template === EXTENSION_WON_LIST_TEMPLATES.history
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../styles/extension-won.scss";

.won-list-head {
  padding: 2em 3em 0;

  &__cell {
    line-height: 1.4;
    color: $color-dark-grey;
  }
}
</style>

<i18n>
{
  "en": {
    "CAR_HEAD_CELL": "CAR",
    "WINNER_HEAD_CELL": "WINNER",
    "DOC_TYPE_HEAD_CELL": "DOC TYPE",
    "LOCATION_HEAD_CELL": "LOCATION",
    "VEHICLE_HEAD_CELL": "VEHICLE",
    "FEE_A_HEAD_CELL": "FEE A",
    "INVOICE_HEAD_CELL": "INVOICE",
    "DUE_HEAD_CELL": "DUE",
    "STATUS_HEAD_CELL": "STATUS",
    "CURRENT_BID_HEAD_CELL": "CURRENT BID",
    "MAX_BID_HEAD_CELL": "MAX BID",
    "LEFT_HEAD_CELL": "LEFT"
  },
  "ka": {
    "CAR_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "WINNER_HEAD_CELL": "ᲛᲝᲒᲔᲑᲣᲚᲘ",
    "DOC_TYPE_HEAD_CELL": "ᲓᲝᲙ. ᲢᲘᲞᲘ",
    "LOCATION_HEAD_CELL": "ᲚᲝᲙᲐᲪᲘᲐ",
    "VEHICLE_HEAD_CELL": "ᲐᲕᲢᲝᲛᲝᲑᲘᲚᲘ",
    "FEE_A_HEAD_CELL": "ᲡᲐᲙᲝᲛᲘᲡᲘᲝ",
    "INVOICE_HEAD_CELL": "ᲘᲜᲕᲝᲘᲡᲘ",
    "DUE_HEAD_CELL": "ᲓᲐᲡᲐᲤᲐᲠᲘ",
    "STATUS_HEAD_CELL": "ᲡᲢᲐᲢᲣᲡᲘ",
    "CURRENT_BID_HEAD_CELL": "ᲛᲘᲛᲓᲘᲜᲐᲠᲔ ᲑᲘᲯᲘ",
    "MAX_BID_HEAD_CELL": "ᲛᲐᲥᲡ. ᲑᲘᲯᲘ",
    "LEFT_HEAD_CELL": "ᲒᲐᲛᲝᲕᲘᲓᲐ"
  },
  "ru": {
    "CAR_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПОБЕДИТЕЛЬ",
    "DOC_TYPE_HEAD_CELL": "ТИП ДОКУМЕНТА",
    "LOCATION_HEAD_CELL": "ЛОКАЦИЯ",
    "VEHICLE_HEAD_CELL": "ЦЕНА",
    "FEE_A_HEAD_CELL": "КОМИССИЯ",
    "INVOICE_HEAD_CELL": "СЧËТ",
    "DUE_HEAD_CELL": "ДОЛГ",
    "STATUS_HEAD_CELL": "СТАТУС",
    "CURRENT_BID_HEAD_CELL": "ТЕК. СТАВКА",
    "MAX_BID_HEAD_CELL": "МАКС. СТАВКА",
    "LEFT_HEAD_CELL": "ОТБЫЛ"
  },
  "uk": {
    "CAR_HEAD_CELL": "АВТО",
    "WINNER_HEAD_CELL": "ПЕРЕМОЖЕЦЬ",
    "DOC_TYPE_HEAD_CELL": "ТИП ДОКУМЕНТА",
    "LOCATION_HEAD_CELL": "ЛОКАЦІЯ",
    "VEHICLE_HEAD_CELL": "ЦІНА",
    "FEE_A_HEAD_CELL": "КОМІСІЯ",
    "INVOICE_HEAD_CELL": "РАХУНОК",
    "DUE_HEAD_CELL": "БОРГ",
    "STATUS_HEAD_CELL": "СТАТУС",
    "CURRENT_BID_HEAD_CELL": "ПОТ. СТАВКА",
    "MAX_BID_HEAD_CELL": "МАКС. СТАВКА",
    "LEFT_HEAD_CELL": "ВІДБУВ"
  }
}
</i18n>
