var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"extension-won"},[_c('page-subnav',{staticClass:"extension-won__subnav"},[_c('won-list-params',{staticClass:"extension-won__subnav-action",attrs:{"value":{
        identity: _vm.$route.query.identity || '',
        copartLogin: _vm.$route.query.copartLogin || '',
        isLookingForWinner: _vm.$route.query.isLookingForWinner === 'true',
        minSoldAt: _vm.$route.query.minSoldAt || '',
        maxSoldAt: _vm.$route.query.maxSoldAt || '',
      },"template":_vm.template},on:{"input":_vm.onListParamsChange}}),_vm._v(" "),_c('subnav-search',{staticClass:"extension-won__subnav-action extension-won__subnav-action_ml",attrs:{"value":_vm.$route.query.search},on:{"input":_vm.onSearch}}),_vm._v(" "),_c('template',{slot:"right"},[_c('router-link',{staticClass:"extension-won__tab extension-won__tab_highlighted",attrs:{"active-class":"extension-won__tab_active","to":{
          params: { tab: _vm.COPART_WON_URL_PARAM_TABS.notPaid },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('NOT_PAID_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"extension-won__tab",attrs:{"active-class":"extension-won__tab_active","to":{
          params: { tab: _vm.COPART_WON_URL_PARAM_TABS.won },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('WON_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"extension-won__tab",attrs:{"active-class":"extension-won__tab_active","to":{
          params: { tab: _vm.COPART_WON_URL_PARAM_TABS.openItems },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('OPEN_ITEMS_TAB'))+"\n      ")]),_vm._v(" "),_c('router-link',{staticClass:"extension-won__tab",attrs:{"active-class":"extension-won__tab_active","to":{
          params: { tab: _vm.COPART_WON_URL_PARAM_TABS.history },
        }}},[_vm._v("\n        "+_vm._s(_vm.$t('HISTORY_TAB'))+"\n      ")])],1)],2),_vm._v(" "),_c('won-list',{staticClass:"extension-won__list",attrs:{"list":_vm.list,"template":_vm.template,"load-more":_vm.loadMoreWrapper},on:{"update-list-ask":_vm.loadList}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }