<template>
  <div class="extension-won">
    <page-subnav class="extension-won__subnav">
      <won-list-params
        class="extension-won__subnav-action"
        :value="{
          identity: $route.query.identity || '',
          copartLogin: $route.query.copartLogin || '',
          isLookingForWinner: $route.query.isLookingForWinner === 'true',
          minSoldAt: $route.query.minSoldAt || '',
          maxSoldAt: $route.query.maxSoldAt || '',
        }"
        :template="template"
        @input="onListParamsChange"
      />

      <subnav-search
        class="extension-won__subnav-action extension-won__subnav-action_ml"
        :value="$route.query.search"
        @input="onSearch"
      />

      <template slot="right">
        <router-link
          class="extension-won__tab extension-won__tab_highlighted"
          active-class="extension-won__tab_active"
          :to="{
            params: { tab: COPART_WON_URL_PARAM_TABS.notPaid },
          }"
        >
          {{ $t('NOT_PAID_TAB') }}
        </router-link>

        <router-link
          class="extension-won__tab"
          active-class="extension-won__tab_active"
          :to="{
            params: { tab: COPART_WON_URL_PARAM_TABS.won },
          }"
        >
          {{ $t('WON_TAB') }}
        </router-link>

        <router-link
          class="extension-won__tab"
          active-class="extension-won__tab_active"
          :to="{
            params: { tab: COPART_WON_URL_PARAM_TABS.openItems },
          }"
        >
          {{ $t('OPEN_ITEMS_TAB') }}
        </router-link>

        <router-link
          class="extension-won__tab"
          active-class="extension-won__tab_active"
          :to="{
            params: { tab: COPART_WON_URL_PARAM_TABS.history },
          }"
        >
          {{ $t('HISTORY_TAB') }}
        </router-link>
      </template>
    </page-subnav>

    <won-list
      class="extension-won__list"
      :list="list"
      :template="template"
      :load-more="loadMoreWrapper"
      @update-list-ask="loadList"
    />
  </div>
</template>

<script>
import PageSubnav from 'Common/PageSubnav'
import SubnavSearch from 'Common/SubnavSearch'

import WonList from './components/WonList'
import WonListParams from './components/WonListParams'

import { mapActions, mapGetters } from 'vuex'
import { extensionWonActions, extensionWonGetters } from './store/types'

import { EXTENSION_WON_LIST_TEMPLATES } from './constants'
import { COPART_WON_URL_PARAM_TABS } from 'Constants/extensionWonUrlParams'

import { showError } from 'Utils/notifications'

import isEqual from 'lodash/isEqual'
import debounce from 'lodash/debounce'

const TAB_TO_TEMPLATE_MAP = Object.freeze({
  [COPART_WON_URL_PARAM_TABS.notPaid]: EXTENSION_WON_LIST_TEMPLATES.notPaid,
  [COPART_WON_URL_PARAM_TABS.won]: EXTENSION_WON_LIST_TEMPLATES.won,
  [COPART_WON_URL_PARAM_TABS.openItems]:
    EXTENSION_WON_LIST_TEMPLATES.openItems,
  [COPART_WON_URL_PARAM_TABS.history]:
    EXTENSION_WON_LIST_TEMPLATES.history,
})

const TAB_TO_LOAD_ACTION_MAP = Object.freeze({
  [COPART_WON_URL_PARAM_TABS.notPaid]:
    extensionWonActions.LOAD_NOT_PAID_LIST,
  [COPART_WON_URL_PARAM_TABS.won]:
    extensionWonActions.LOAD_WON_LIST,
  [COPART_WON_URL_PARAM_TABS.openItems]:
    extensionWonActions.LOAD_OPEN_ITEMS_LIST,
  [COPART_WON_URL_PARAM_TABS.history]:
    extensionWonActions.LOAD_HISTORY_LIST,
})

export default {
  name: 'extension-won',
  components: {
    PageSubnav,
    SubnavSearch,
    WonList,
    WonListParams,
  },

  data () {
    return {
      template: TAB_TO_TEMPLATE_MAP[COPART_WON_URL_PARAM_TABS.won],

      COPART_WON_URL_PARAM_TABS
    }
  },

  computed: {
    ...mapGetters('ui/extension-won', {
      list: extensionWonGetters.LIST,
      isLoading: extensionWonGetters.IS_LOADING,
    }),

    metaTitle () {
      const tabTitles = {
        [COPART_WON_URL_PARAM_TABS.notPaid]: this.$t('META_TITLE_NOT_PAID'),
        [COPART_WON_URL_PARAM_TABS.won]: this.$t('META_TITLE_WON'),
        [COPART_WON_URL_PARAM_TABS.openItems]: this.$t('META_TITLE_OPEN_ITEMS'),
        [COPART_WON_URL_PARAM_TABS.history]: this.$t('META_TITLE_HISTORY'),
      }
      return tabTitles[this.$route.params.tab]
    },

    loadMoreWrapper () {
      return async () => {
        await this.loadMore(this.listPayload)
      }
    },

    listPayload () {
      return {
        filter: {
          search: this.$route.query.search || undefined,
          identity: this.$route.query.identity || undefined,
          lookingForWinner: this.$route.query.isLookingForWinner === 'true' || undefined,
          minSoldAt: this.$route.query.minSoldAt || undefined,
          maxSoldAt: this.$route.query.maxSoldAt || undefined,
        }
      }
    }
  },

  watch: {
    '$route.params.tab': {
      immediate: true,
      handler (tab, oldTab) {
        if (isEqual(tab, oldTab)) return
        this.onListParamsChange(this.$route.query)
      }
    },
  },

  created () {
    this.loadManagedLogins()
  },

  methods: {
    ...mapActions('ui/extension-won', {
      loadListAction: extensionWonActions.LOAD_LIST,
      loadMore: extensionWonActions.LOAD_MORE,
      setIsLoading: extensionWonActions.SET_IS_LOADING,
      loadManagedLogins: extensionWonActions.LOAD_MANAGED_LOGINS,
    }),

    onListParamsChange (input) {
      const query = { ...this.$route.query }

      query.identity = input.identity || undefined
      query.isLookingForWinner = input.isLookingForWinner || undefined

      query.minSoldAt = input.minSoldAt || undefined
      query.maxSoldAt = input.maxSoldAt || undefined

      query.copartLogin = input.copartLogin || undefined
      query.search = query.copartLogin || query.search

      if (!isEqual(query, this.$route.query)) {
        this.$router.push({ query })
      }

      this.loadList()
    },

    async loadList () {
      try {
        const tab = this.$route.params.tab
        await this.loadListAction({
          action: TAB_TO_LOAD_ACTION_MAP[tab],
          opts: this.listPayload
        })

        this.template = TAB_TO_TEMPLATE_MAP[tab]
      } catch (error) {
        if (!error.isCanceled) {
          showError(this.$t('LIST_FETCH_FAILED_NOTIFY'))
          console.error(error)
        }
      }
    },

    onSearch (value) {
      const querySearch = this.$route.query.search || ''
      const search = value.trim()

      const MIN_SEARCH_LENGTH = 5
      const isValidLength = search.length === 0 ||
        search.length >= MIN_SEARCH_LENGTH

      if (search === querySearch || !isValidLength) return

      this.setIsLoading(true)
      this.applySearchDebounced(search)
    },

    applySearchDebounced: debounce(async function (value) {
      this.$router.push({
        query: {
          search: value || undefined,
          copartLogin: undefined
        }
      })
      this.loadList()
    }, 500),
  },

  metaInfo () {
    return {
      title: this.metaTitle,
      titleTemplate: `%s | ${this.$t('COMMON.APP_TITLE')}`,
    }
  }
}
</script>

<style lang="scss" scoped>
.extension-won {
  display: flex;
  flex-direction: column;
  flex: 1;

  &__create-btn {
    & /deep/ .ui-button__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &-icon {
      display: block;
      margin-left: 1em;
      font-size: 1.2em;
    }
  }

  &__tab {
    position: relative;
    height: 100%;
    display: flex;
    align-content: center;
    align-items: center;
    text-decoration: none;
    margin: 0 1.4em;
    color: $color-ui-secondary;
    font-weight: 500;
    letter-spacing: 0.03em;

    &_active {
      color: $color-dark;
      box-shadow: 0 -1px 0 $color-dark inset !important;
    }

    &:hover {
      box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.1) inset;
    }

    &_highlighted {
      color: $color-flag-is-error;
    }

    &_highlighted.extension-won__tab_active {
      box-shadow: 0 -1px 0 $color-flag-is-error inset !important;
    }
  }
}
</style>

<i18n>
{
  "en": {
    "NOT_PAID_TAB": "NOT PAID",
    "WON_TAB": "WON",
    "OPEN_ITEMS_TAB": "OPEN ITEMS",
    "HISTORY_TAB": "HISTORY",
    "META_TITLE_NOT_PAID": "Not Paid",
    "META_TITLE_WON": "Won",
    "META_TITLE_OPEN_ITEMS": "Open Items",
    "META_TITLE_HISTORY": "History",
    "LIST_FETCH_FAILED_NOTIFY": "Cannot load the won list. Please try again later or contact the system owner."
  },
  "ka": {
    "NOT_PAID_TAB": "ᲒᲐᲓᲐᲡᲐᲮᲓᲔᲚᲘ",
    "WON_TAB": "ᲛᲝᲒᲔᲑᲣᲚᲘ",
    "OPEN_ITEMS_TAB": "ᲛᲝᲡᲐᲤᲘᲥᲠᲔᲑᲔᲚᲘ",
    "HISTORY_TAB": "ᲘᲡᲢᲝᲠᲘᲐ",
    "META_TITLE_NOT_PAID": "გადასახდელი",
    "META_TITLE_WON": "მოგებული",
    "META_TITLE_OPEN_ITEMS": "მოსაფიქრებელი",
    "META_TITLE_HISTORY": "ისტორია",
    "LIST_FETCH_FAILED_NOTIFY": "მოგებული ვერ ჩაიტვირთა, მიმართეთ ადმინისტრატორს."
  },
  "ru": {
    "NOT_PAID_TAB": "НЕОПЛАЧЕННЫЕ",
    "WON_TAB": "ВЫИГРАННЫЕ",
    "OPEN_ITEMS_TAB": "ОТКРЫТЫЕ ПОЗИЦИИ",
    "HISTORY_TAB": "ИСТОРИЯ",
    "META_TITLE_NOT_PAID": "Неоплаченные",
    "META_TITLE_WON": "Выигранные",
    "META_TITLE_OPEN_ITEMS": "Открытые Позиции",
    "META_TITLE_HISTORY": "История",
    "LIST_FETCH_FAILED_NOTIFY": "Не удается загрузить список выигранных лотов. Повторите попытку позже или свяжитесь с владельцем системы."
  },
  "uk": {
    "NOT_PAID_TAB": "НЕ СПЛАЧЕНІ",
    "WON_TAB": "ВИГРАНІ",
    "OPEN_ITEMS_TAB": "ВІДКРИТІ ПОЗИЦІЇ",
    "HISTORY_TAB": "ІСТОРІЯ",
    "META_TITLE_NOT_PAID": "Не Сплачені",
    "META_TITLE_WON": "Виграні",
    "META_TITLE_OPEN_ITEMS": "Відкриті Позиції",
    "META_TITLE_HISTORY": "Історія",
    "LIST_FETCH_FAILED_NOTIFY": "Не вдається завантажити список виграних лотів. Повторіть спробу пізніше або зв’яжіться з власником системи."
  }
}
</i18n>
