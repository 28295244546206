import { ModelBase } from './ModelBase'
import { ExtensionParticipation } from './ExtensionParticipation'
import { ExtensionVehicle } from './ExtensionVehicle'

import { sdk } from 'Services/shelfNetworkSdk'
import { getNullDate } from 'Utils/dateHelpers'

import get from 'lodash/get'

export class CopartWinner extends ModelBase {
  constructor (raw = {}, cache = {}) {
    super(raw, cache)

    this.buyerNumber = this._str(raw.buyerNumber || raw.bidder)
    this.currency = this._str(raw.currency || raw.cc).trim()
    this.lookingForWinner = Boolean(raw.lookingForWinner)
    this.description = this._str(raw.description || raw.invoiceDescription)
      .trim()

    this.totalAmount = this._strFiat(raw.totalAmount)
    this.paymentStatus = this._str(raw.paymentStatus)
    this.secretId = this._str(raw.secretId)
    this.counterBidStatus = this._str(raw.counterBidStatus)
    this.gatePassCode = this._str(raw.gatePassCode)

    this.saleDate = this._date(raw.saleDate || raw.invoiceDate)
    this.leftLocationDate = this._date(raw.lly)

    this.amountDue = this._num(raw.balanceDue)
    this.bidAmount = this._num(raw.highBidPrice)
    this.invoiceAmount = this._num(raw.totalAmount)

    this.currentBid = this._num(raw.currentBid)
    this.myBid = this._num(raw.myBid)

    const r11s = raw.relationships || {}

    this.participation = ModelBase.modelFromCache(
      ExtensionParticipation,
      r11s.participation || {},
      cache
    )

    this.vehicle = ModelBase.modelFromCache(
      ExtensionVehicle,
      r11s.vehicle || {},
      cache
    )

    this.lotSaleId = this._str(get(r11s, 'lotSale.id'))

    this.dailyStatsCount = this._num(get(r11s, 'dailyStats.count'))
  }

  get feeAmount () {
    return Math.max(this.invoiceAmount - this.bidAmount, 0)
  }

  get isPaid () {
    return this.paymentStatus === 'Paid'
  }

  get invoiceLink () {
    return sdk.copartBackOffice.getInvoiceUrlV2({
      query: {
        invoiceNumber: this.id,
        secretId: this.secretId,
        yardNumber: this.vehicle.yardNumber,
      }
    })
  }

  _convertCopartDate (rawDate) {
    if (!Number(rawDate)) return getNullDate()

    const dateStr = String(rawDate)
    const year = dateStr.slice(0, 4)
    const month = dateStr.slice(4, 6)
    const day = dateStr.slice(6)

    return new Date(year, month - 1, day)
  }
}
