<template>
  <div class="cells-cmn__cell">
    <span class="cells-cmn__str cells-cmn__str_sec">
      <template v-if="date.getTime()">
        {{
          $fd(date, $t('DATE_FORMATS.MONTH_DAY_YEAR_NUMERAL'))
        }}
      </template>

      <template v-else>
        {{ $t('COMMON.SYMBOLS.MDASH') }}
      </template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'won-date-cell',

  props: {
    date: { type: Date, required: true },
  },
}
</script>

<style lang="scss" scoped>
@import "@/common/cells/cells.scss";
</style>
